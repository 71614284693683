import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 5rem;
  padding:1rem;
  width: 100%;
  max-width: 900px;
  
  @media (min-width:900px){
    padding:5rem;
  }
`

export const PageWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width:900px){
    padding:5rem;

`