import {GatsbyImage, getImage} from "gatsby-plugin-image";
import React from "react";
import styled from "@emotion/styled";

const MaxHeight = styled.div`
  max-height: 90vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
`

const Meme = ({data} : any) =>{
    const image = getImage(data.node.childImageSharp.gatsbyImageData)
    return (
        <MaxHeight>
            <GatsbyImage image={image} loading={"lazy"} alt={"n/a"} objectFit={"contain"}/>
        </MaxHeight>
    )
}

export default Meme;