import React, {createRef, useEffect, useRef} from 'react';
import styled from "@emotion/styled";

const WidthFull = styled.div`
  & video {
    width:100%;
    max-height: 90vh;
  }
  & img {
    width:100%;
    max-height: 90vh;
  }
`

const VideoPlayer = ({data}: any) => {
    const vid : any = useRef();
    useEffect(()=>{
        if(vid.current !== null && vid.current !== undefined ) {
            if (vid.current.volume !== null) {
                vid.current.volume = 0.2;
            }
        }
    })

    return (
        <WidthFull style={{scrollSnapAlign: "center"}}>
            {data.extension === "gif"?
            <img src={data.publicURL} alt=""/>
            :
            <video controls ref={vid} preload="metadata">
                <source src={`${data.publicURL}#t=0.1`} type={`video/${data.extension}`}/>
            </video>
        }
        </WidthFull>
    );
};

export default VideoPlayer;